import { createSlice } from '@reduxjs/toolkit';
import { Tag, TagWithUsers } from 'src/models/tag';

interface TagState {
  isLoading: boolean;
  isErrorMessage: string;
  statusCode: number;
  tags: Tag[];
  activeTag: Tag | {};
  tagPaginated: Tag[];
  tagsWithUsers: TagWithUsers[]; 
  page: number;
  limit: number;
  lastVisible: any;
  changeTag: null;
  totalTags: number;
  totalPages: number; 
  totalUsers: number; 
  pageSize: number; 
  usersPaginated: any[]; 
  currentPage: number
  selectedTagId: null,
}

const initialState: TagState = {
  isLoading: false,
  statusCode: 201,
  isErrorMessage: '',
  tags: [],
  activeTag: {},
  tagPaginated: [],
  tagsWithUsers: [], 
  page: 0,
  limit: 25,
  lastVisible: null,
  changeTag: null,
  totalTags: 0,
  totalPages: 0, 
  totalUsers: 0, 
  usersPaginated: [],
  pageSize: 25, 
  currentPage: 0,
  selectedTagId: null
};

export const tagSlice = createSlice({
  name: 'tag',
  initialState,
  reducers: {
    startLoadingTags: (state) => {
      state.isLoading = true;
    },
    stopLoadingTags: (state) => {
      state.isLoading = false;
    },
    setTags: (state, action) => {
      state.isLoading = false;
      state.tags = [...action.payload];
    },
    setTagsPaginated: (state, action) => {
      state.isLoading = false;
      state.page = action.payload.page;
      state.limit = action.payload.limit;
      state.tagPaginated = action.payload.tags;
      state.lastVisible = action.payload.lastVisible;
      state.totalTags = action.payload.totalTags;
      
      state.tags = action.payload.tags
    },
    setTagsWithUsers: (state, action) => {
      state.isLoading = false;
      state.tagsWithUsers = action.payload.results;
      state.totalTags = action.payload.totalTags;
      state.page = action.payload.currentPage;
      state.totalPages = action.payload.totalPages;
    },
    setUsersPaginated: (state, action) => {
      state.isLoading = false;
      state.totalUsers = action.payload.totalUsers;
      state.totalPages = action.payload.totalPages;
      state.currentPage = action.payload.currentPage
      state.usersPaginated = action.payload.data;
    },
    setSelectedTagId: (state, action) => {
      state.selectedTagId = action.payload;
    },
    setChangeTag: (state, action) => {
      const tagId = action.payload;
      state.tags = state.tags.filter(tag => tag.id !== tagId);
      state.tagsWithUsers = state.tagsWithUsers.filter(tagWithUsers => tagWithUsers.tag?.id !== tagId);
    
      state.changeTag = tagId;
    },
    
    setError: (state, action) => {
      const message = action.payload.message;
      state.statusCode = action.payload.status;
      state.isErrorMessage = message;
    }
  }
});

export const {
  startLoadingTags,
  stopLoadingTags,
  setTagsPaginated,
  setTagsWithUsers, 
  setUsersPaginated,
  setError,
  setChangeTag,
  setTags,
  setSelectedTagId 
} = tagSlice.actions;
